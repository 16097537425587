<template>
  <div v-if="this.$store.state.loader">
    <loader
      object="#f74b3d"
      color1="#ffffff"
      color2="#17fd3d"
      size="5"
      speed="2"
      bg="#343a40"
      objectbg="#999793"
      opacity="80"
      disableScrolling="false"
      name="spinning"
    ></loader>
  </div>
  <div v-else class="content-right rounded-3 dashboard-card">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <a href="javascript:void(0)">Admin</a>
        </li>
        <li class="breadcrumb-item active">Dashboard</li>
      </ol>
    </nav>
    <div class="content-body" id="fullHeight">
      <h2 class="title2">Dashboard</h2>
      <div class="row">
        <div class="col-lg-3 mb-3">
          <div class="card">
            <div class="card-body">
              <div class="d-flex justify-content-between">
                <div class="text-start">
                  <h2 class="card-title">Employees</h2>
                  <table style="width: 100%">
                    <tbody>
                      <tr>
                        <td>Regular</td>
                        <td>
                          <router-link
                            :to="{
                              name: 'filtered-employee',
                              params: { filterString: 'regular' },
                            }"
                            >{{ dashboardData.emp_typeR }}</router-link
                          >
                        </td>
                      </tr>
                      <tr>
                        <td>Trainee</td>
                        <td>
                          <router-link
                            :to="{
                              name: 'filtered-employee',
                              params: { filterString: 'trainee' },
                            }"
                            >{{ dashboardData.emp_typeT }}</router-link
                          >
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div class="card-icon">
                  <i class="icon-user-tie"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-3 mb-3">
          <div class="card">
            <div class="card-body">
              <div class="d-flex justify-content-between">
                <div class="text-start">
                  <h2 class="card-title">Attendance</h2>
                  <table style="width: 100%">
                    <tbody>
                      <tr>
                        <td>Online</td>
                        <td>
                          <router-link :to="{ name: 'attendances' }">{{
                            dashboardData.is_online_now
                          }}</router-link>
                        </td>
                      </tr>
                      <tr>
                        <td>Offline</td>
                        <td>
                          <router-link :to="{ name: 'offline-employees' }">{{
                            dashboardData.is_offline_now
                          }}</router-link>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div class="card-icon">
                  <i class="icon-calendar"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-3 mb-3">
          <div class="card">
            <div class="card-body">
              <div class="d-flex justify-content-between">
                <div class="text-start">
                  <h2 class="card-title">Leaves of Absence</h2>
                  <table style="width: 100%">
                    <tbody>
                      <tr>
                        <td>Approved</td>
                        <td>
                          <router-link
                            :to="{
                              name: 'leave-filter',
                              params: { filterString: 'Approved' },
                            }"
                            >{{ dashboardData.emp_leaves_approve }}</router-link
                          >
                        </td>
                      </tr>
                      <tr>
                        <td>Pending</td>
                        <td>
                          <router-link
                            :to="{
                              name: 'leave-filter',
                              params: { filterString: 'Pending' },
                            }"
                            >{{ dashboardData.emp_leaves_pending }}</router-link
                          >
                        </td>
                      </tr>
                      <tr>
                        <td>Rejected</td>
                        <td>
                          <router-link
                            :to="{
                              name: 'leave-filter',
                              params: { filterString: 'Rejected' },
                            }"
                            >{{
                              dashboardData.emp_leaves_rejected
                            }}</router-link
                          >
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div class="card-icon">
                  <i class="icon-exit"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-3 mb-3">
          <div class="card">
            <div class="card-body">
              <div class="d-flex justify-content-between">
                <div class="text-start">
                  <h2 class="card-title">Late Arrivals/ Early Out Employees</h2>
                  <table style="width: 100%">
                    <tbody>
                      <tr>
                        <td>Late in</td>
                        <td>
                          <router-link
                            :to="{
                              name: 'attendance-filter',
                              params: { filterString: 'late-in' },
                            }"
                            >View</router-link
                          >
                        </td>
                      </tr>
                      <tr>
                        <td>Early out</td>
                        <td>
                          <router-link
                            :to="{
                              name: 'attendance-filter',
                              params: { filterString: 'early-out' },
                            }"
                            >View</router-link
                          >
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div class="card-icon">
                  <i class="icon-calendar"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="t-layout">
        <!-- <div class="row">
        <div class="col-lg-6 mb-3">
          <div class="card">
            <div class="card-header">
                <h5>Newest Employees</h5>
            </div>
            <div class="card-body">
              <b-table
                class="table table-custom"
                :items="this.$store.state.dashboardData.emp_all_type"
                :fields="NewEmployeesfields"
              >
                <template v-slot:cell(name)="data">
                  {{ data.item.lastname }}, {{ data.item.firstname }}
                </template>
                <template v-slot:cell(start_date)="data">
                  {{ data.item.startdate}}
                </template>
              </b-table>
            </div>
          </div>
        </div>
        <div class="col-lg-6 mb-3">
            <div class="card">
            <div class="card-header">
                <h5>Recent Attendances</h5>
            </div>
            <div class="card-body">
              <b-table
                class="table table-custom"
                :items="attendance"
                :fields="attendancefields"
              >
                <template v-slot:cell(name)="data">
                  {{ data.item.employee }}
                </template>
                <template v-slot:cell(type)="data">
                  {{ data.item.timein != null && data.item.timeout == null ? 'Time-In' : 'Time-Out' }}
                </template>
                <template v-slot:cell(time)="data">
                  {{ data.item.timein}}
                </template>
              </b-table>
            </div>
          </div>
        </div>
        <div class="col-lg-6 mb-3">
            <div class="card">
            <div class="card-header">
                <h5>Recent Leaves of Absence</h5>
            </div>
            <div class="card-body">
              <b-table
                class="table table-custom"
                :items="recentLeaves"
                :fields="recentLeavesfields"
                show-empty
              >
                <template v-slot:cell(name)="data">
                  {{ data.item.employee }}
                </template>
                <template v-slot:cell(date)="data">
                  {{ data.item.leavefrom}}
                </template>
              </b-table>
            </div>
          </div>
        </div>
      </div> -->
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      NewEmployeesfields: [
        {
          key: "name",
          label: "Name",
          tdAttr: { "custom-label": "Name:" },
        },
        {
          key: "jobposition",
          label: "Position",
          tdAttr: { "custom-label": "Position:" },
        },
        {
          key: "start_date",
          tdAttr: { "custom-label": "Start Date:" },
        },
      ],
      attendancefields: [
        {
          key: "name",
          tdAttr: { "custom-label": "Name:" },
        },
        {
          key: "type",
          tdAttr: { "custom-label": "Type:" },
        },
        {
          key: "time",
          tdAttr: { "custom-label": "Time:" },
        },
      ],
      recentLeavesfields: [
        {
          key: "name",
          tdAttr: { "custom-label": "Name:" },
        },
        {
          key: "date",
          tdAttr: { "custom-label": "Date:" },
        },
      ],
    };
  },
  computed: {
    dashboardData() {
      return this.$store.state.dashboardData;
    },
    newEmployees() {
      return this.$store.state.dashboardData.emp_all_type;
    },
    attendance() {
      return this.$store.state.dashboardData.a;
    },
    recentLeaves() {
      return this.$store.state.dashboardData.emp_approved_leave;
    },
  },
  created() {
    this.$store.dispatch("switchLoader", true);
    this.$store.dispatch("loadNewestEmployees");
    this.$store.dispatch("loadPermissions");
    this.$store.dispatch("loadLeavesdata");
    this.$store.dispatch("loadEmployees");
    this.$store.dispatch("loadAttendancesdata");
    var token = localStorage.getItem("token");
    var role_id = localStorage.getItem("role_id");
    if (token == "" || token == null) {
      window.location.href = "/login";
    }
    if (role_id == 7) {
      window.location.href = "/login";
    }
    
  },
};
</script>
