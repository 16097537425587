var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (this.$store.state.loader)?_c('div',[_c('loader',{attrs:{"object":"#f74b3d","color1":"#ffffff","color2":"#17fd3d","size":"5","speed":"2","bg":"#343a40","objectbg":"#999793","opacity":"80","disableScrolling":"false","name":"spinning"}})],1):_c('div',{staticClass:"content-right rounded-3 dashboard-card"},[_vm._m(0),_c('div',{staticClass:"content-body",attrs:{"id":"fullHeight"}},[_c('h2',{staticClass:"title2"},[_vm._v("Dashboard")]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-3 mb-3"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"d-flex justify-content-between"},[_c('div',{staticClass:"text-start"},[_c('h2',{staticClass:"card-title"},[_vm._v("Employees")]),_c('table',{staticStyle:{"width":"100%"}},[_c('tbody',[_c('tr',[_c('td',[_vm._v("Regular")]),_c('td',[_c('router-link',{attrs:{"to":{
                            name: 'filtered-employee',
                            params: { filterString: 'regular' },
                          }}},[_vm._v(_vm._s(_vm.dashboardData.emp_typeR))])],1)]),_c('tr',[_c('td',[_vm._v("Trainee")]),_c('td',[_c('router-link',{attrs:{"to":{
                            name: 'filtered-employee',
                            params: { filterString: 'trainee' },
                          }}},[_vm._v(_vm._s(_vm.dashboardData.emp_typeT))])],1)])])])]),_vm._m(1)])])])]),_c('div',{staticClass:"col-lg-3 mb-3"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"d-flex justify-content-between"},[_c('div',{staticClass:"text-start"},[_c('h2',{staticClass:"card-title"},[_vm._v("Attendance")]),_c('table',{staticStyle:{"width":"100%"}},[_c('tbody',[_c('tr',[_c('td',[_vm._v("Online")]),_c('td',[_c('router-link',{attrs:{"to":{ name: 'attendances' }}},[_vm._v(_vm._s(_vm.dashboardData.is_online_now))])],1)]),_c('tr',[_c('td',[_vm._v("Offline")]),_c('td',[_c('router-link',{attrs:{"to":{ name: 'offline-employees' }}},[_vm._v(_vm._s(_vm.dashboardData.is_offline_now))])],1)])])])]),_vm._m(2)])])])]),_c('div',{staticClass:"col-lg-3 mb-3"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"d-flex justify-content-between"},[_c('div',{staticClass:"text-start"},[_c('h2',{staticClass:"card-title"},[_vm._v("Leaves of Absence")]),_c('table',{staticStyle:{"width":"100%"}},[_c('tbody',[_c('tr',[_c('td',[_vm._v("Approved")]),_c('td',[_c('router-link',{attrs:{"to":{
                            name: 'leave-filter',
                            params: { filterString: 'Approved' },
                          }}},[_vm._v(_vm._s(_vm.dashboardData.emp_leaves_approve))])],1)]),_c('tr',[_c('td',[_vm._v("Pending")]),_c('td',[_c('router-link',{attrs:{"to":{
                            name: 'leave-filter',
                            params: { filterString: 'Pending' },
                          }}},[_vm._v(_vm._s(_vm.dashboardData.emp_leaves_pending))])],1)]),_c('tr',[_c('td',[_vm._v("Rejected")]),_c('td',[_c('router-link',{attrs:{"to":{
                            name: 'leave-filter',
                            params: { filterString: 'Rejected' },
                          }}},[_vm._v(_vm._s(_vm.dashboardData.emp_leaves_rejected))])],1)])])])]),_vm._m(3)])])])]),_c('div',{staticClass:"col-lg-3 mb-3"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"d-flex justify-content-between"},[_c('div',{staticClass:"text-start"},[_c('h2',{staticClass:"card-title"},[_vm._v("Late Arrivals/ Early Out Employees")]),_c('table',{staticStyle:{"width":"100%"}},[_c('tbody',[_c('tr',[_c('td',[_vm._v("Late in")]),_c('td',[_c('router-link',{attrs:{"to":{
                            name: 'attendance-filter',
                            params: { filterString: 'late-in' },
                          }}},[_vm._v("View")])],1)]),_c('tr',[_c('td',[_vm._v("Early out")]),_c('td',[_c('router-link',{attrs:{"to":{
                            name: 'attendance-filter',
                            params: { filterString: 'early-out' },
                          }}},[_vm._v("View")])],1)])])])]),_vm._m(4)])])])])]),_c('div',{staticClass:"t-layout"})])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('nav',{attrs:{"aria-label":"breadcrumb"}},[_c('ol',{staticClass:"breadcrumb"},[_c('li',{staticClass:"breadcrumb-item"},[_c('a',{attrs:{"href":"javascript:void(0)"}},[_vm._v("Admin")])]),_c('li',{staticClass:"breadcrumb-item active"},[_vm._v("Dashboard")])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card-icon"},[_c('i',{staticClass:"icon-user-tie"})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card-icon"},[_c('i',{staticClass:"icon-calendar"})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card-icon"},[_c('i',{staticClass:"icon-exit"})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card-icon"},[_c('i',{staticClass:"icon-calendar"})])}]

export { render, staticRenderFns }